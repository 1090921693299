import config from "~/config"
import chroma from 'chroma-js'

import {
  TypographyScale,
  floatToMinimalSting,
  wrapFontFamily
} from "./typography"

const ts = new TypographyScale(config.typography)

export const primaryColor       = config.colors.primaryColor
export const secondaryColor     = config.colors.secondaryColor
export const headerColor        = config.colors.headerColor
export const bodyColor          = config.colors.bodyColor
export const contentBackground1 = config.colors.contentBackground1
export const contentBackground2 = config.colors.contentBackground2
export const lightRed1          = config.colors.lightRed1
export const lightBlue1         = config.colors.lightBlue1
export const darkRed1           = config.colors.darkRed1
export const darkBlue1          = config.colors.darkBlue1

export const linkColor      = {
  link:    chroma(config.colors.linkColor).hex(),
  hover:   chroma(config.colors.linkColor).brighten(1).hex(),
  active:  chroma(config.colors.linkColor).darken(1).hex(),
  visited: chroma(config.colors.linkColor).desaturate(1).hex(),
}

/** METHODS */

export const abs2rel              = ts.rel.bind(ts)
export const getLinesHeightRel    = ts.getLinesHeightRel.bind(ts)
export const getLinesHeightAbs    = ts.getLinesHeightAbs.bind(ts)
export const scaleRel             = ts.scaleRel.bind(ts)
export const scaleAbs             = ts.scaleAbs.bind(ts)
export const lineHeightForFontRel = ts.lineHeightForFontRel.bind(ts)
export const lineHeightForFontAbs = ts.lineHeightForFontAbs.bind(ts)

export const em = (n: number) => floatToMinimalSting(n) + 'em'
export const rem = (n: number) => floatToMinimalSting(n) + 'rem'
export const px = (n: number) => n + 'px'

export const linesEm = (n: number) => em(getLinesHeightRel(n))
export const linesRem = (n: number) => rem(getLinesHeightRel(n))
export const linesPix = (n: number) => px(getLinesHeightAbs(n))

export const brighten = (c: string, v: number = 1) => chroma(c).brighten(v).hex()
export const darken   = (c: string, v: number = 1) => chroma(c).darken(v).hex()

/** CONSTS */

export const baseFontSize      = ts.baseFontSize
export const baseWidth         = config.settings.contentWidth

export const baseLineHeight    = ts.lineHeightAbs / baseFontSize
export const lineHeightRel     = ts.lineHeightRel
export const lineHeightAbs     = ts.lineHeightAbs

export const headerWeight      = config.typography.headerWeight.toString()
export const bodyWeight        = config.typography.bodyWeight.toString()
export const boldWeight        = config.typography.boldWeight.toString()

export const headerFontFamily  = config.typography.headerFontFamily.map(wrapFontFamily).join(",")
export const bodyFontFamily    = config.typography.bodyFontFamily.map(wrapFontFamily).join(",")

export const contentWidth      = config.settings.contentWidth

export const h1Size            = scaleRel(5 / 5)
export const h2Size            = scaleRel(3 / 5)
export const h3Size            = scaleRel(2 / 5)
export const h4Size            = scaleRel(0 / 5)
export const h5Size            = scaleRel(-1 / 5)
export const h6Size            = scaleRel(-1.5 / 5)

export const bp = config.breakpoints.map(bp => ({
  ...bp
}))

export const calcBaseFontSize = (scale: number, width: string) => `calc(${scale * baseFontSize / baseWidth} * ${width})`