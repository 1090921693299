import React from 'react'
import styled from 'styled-components'
import { secondaryColor, linesEm } from '~/styling'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'

const Menu = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: ${linesEm(4)};
`

const MenuItem = styled.div`
  margin: ${linesEm(1)} auto;
  width: 100%;
  text-align: center;
  font: 400 2em/1.3 'Jost';
  text-transform: uppercase;
  a {
    color: ${secondaryColor};
    :visited {
      color: ${secondaryColor};
    }
  }
  .active {
    font-weight: 600;
    color: #ad8020;
  }
  cursor: pointer;
`

const Nav: React.FC = () => {
  return (
    <Menu>
      <MenuItem>
        <ScrollLink
          activeClass="active"
          className="about"
          to="about"
          spy={true}
          smooth={true}
          duration={500}
        >
          О приходе
        </ScrollLink>
      </MenuItem>
      <MenuItem>
        <ScrollLink
          activeClass="active"
          className="services"
          to="services"
          spy={true}
          smooth={true}
          duration={500}
        >
          Богослужения
        </ScrollLink>
      </MenuItem>
      <MenuItem>
        <ScrollLink
          activeClass="active"
          className="completion"
          to="completion"
          spy={true}
          smooth={true}
          duration={500}
        >
          Когда будет построен храм?
        </ScrollLink>
      </MenuItem>
      <MenuItem>
        <ScrollLink
          activeClass="active"
          className="howtohelp"
          to="howtohelp"
          spy={true}
          smooth={true}
          duration={500}
        >
          Как помочь строительству?
        </ScrollLink>
      </MenuItem>
      <MenuItem>
        <ScrollLink
          activeClass="active"
          className="donate"
          to="donate"
          spy={true}
          smooth={true}
          duration={500}
        >
          Сделать пожертвование
        </ScrollLink>
      </MenuItem>
      <MenuItem>
        <ScrollLink
          activeClass="active"
          className="contacts"
          to="contacts"
          spy={true}
          smooth={true}
          duration={500}
        >
          Контакты и реквизиты
        </ScrollLink>
      </MenuItem>
    </Menu>
  )
}

export default Nav