import React from 'react'
import styled from 'styled-components'
import GlobalStyle from '~/components/GlobalStyle'
import Sidebar from './Sidebar'
import ContentView from './ContentView'
import Logo from './Logo'
import Nav from './Nav'

const ViewPort = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`

const Layout: React.FC = ({children}) => {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <ViewPort>
        <ContentView>
          {children}
        </ContentView>
        <Sidebar>
          <Logo></Logo>
          <Nav></Nav>
        </Sidebar>
      </ViewPort>
    </>
  )
}

export default Layout