import React from 'react'
import styled from 'styled-components'
import { useAppContext, App } from '~/providers'
import BgPngPath from '~/assets/bg3.png'
import { calcBaseFontSize, bp } from '~/styling'
import { useObservable } from '~/utils'

const sidebarPadding = 0.04

const Base = styled.aside`
  position: fixed;
  top: 0;
  left: -${bp[0].sidebarWidth};
  width: ${bp[0].sidebarWidth};
  height: 100vh;
  padding: calc(${sidebarPadding} * ${bp[0].sidebarWidth});
  overflow-y: scroll;
  background: url(${BgPngPath});
  background-size: 150px 150px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  transition: left 0.4s ease-out;
  font-size: ${calcBaseFontSize(1, bp[0].sidebarWidth)};
  &.drawer {
    left: 0;
  }
  @media (min-width: ${bp[1].minWidth}) {
    left: 0;
    width: ${bp[1].sidebarWidth};
    font-size: ${calcBaseFontSize(1, bp[1].sidebarWidth)};
    padding: calc(${sidebarPadding} * ${bp[1].sidebarWidth});
  }
  @media (min-width: ${bp[2].minWidth}) {
    width: ${bp[2].sidebarWidth};
    font-size: ${calcBaseFontSize(1, bp[2].sidebarWidth)};
    padding: calc(${sidebarPadding} * ${bp[2].sidebarWidth});
  }
`

const Sidebar: React.FC = ({ children }) => {
  const appCtx = useAppContext()
  const [drawer] = useObservable(appCtx.drawer$, appCtx.drawer)
  // console.log('drawer', drawer)
  return <Base className={drawer ? 'drawer' : ''}>{children}</Base>
}

export default Sidebar