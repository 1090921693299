import { createGlobalStyle } from 'styled-components'
import {
  headerFontFamily,
  headerWeight,
  bodyWeight,
  bodyFontFamily,
  linesEm,
  bodyColor,
  headerColor,
  boldWeight,
  linkColor,
  h1Size,
  h2Size,
  h3Size,
  h4Size,
  h5Size,
  h6Size,
} from '~/styling'

export default createGlobalStyle`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: ${linesEm(0.5)} 0 ${linesEm(0.5)};
    color: ${headerColor};
    font-family: ${headerFontFamily};
    font-weight: ${headerWeight};
    text-rendering: optimizeLegibility;
    text-align: center;
    line-height: 1.5;
    hyphens: none;
  }

  h1 {
    font-size: ${h1Size}em;
  }

  h2 {
    font-size: ${h2Size}em;
  }

  h3 {
    font-size: ${h3Size}em;
  }

  h4 {
    font-size: ${h4Size}em;
    font-weight: 500;
  }

  h5 {
    font-size: ${h5Size}em;
    margin-bottom: ${linesEm(0.5)};
    font-weight: 500;
  }

  h6 {
    font-family: ${bodyFontFamily};
    font-size: ${h6Size}em;
    margin-bottom: ${linesEm(0.5)};
    font-style: italic;
    color: #777;
    font-weight: 500;
  }

  p {
    font-size: 1em;
    font-weight: ${bodyWeight};
    hyphens: auto;
    text-align: justify;
  }

  p *:last-child {
    margin-bottom: 0;
  }

  pre, .stih {
    font-family: ${bodyFontFamily};
    font-style: italic;
    text-align: center;
    @media (max-width: 460px) {
      font-size: 0.8em;
    }
  }

  blockquote {
    border-left: 5px solid #ddd;
    padding-left: ${linesEm(0.75)};
    margin-block-start:0;
    margin-block-end: ${linesEm(1)};
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-style: italic;
    color: #484848;
  }

  blockquote *:last-child {
    margin-bottom: 0;
  }

  hr {
    margin-bottom: calc(${linesEm(1)} - 1px);
    background: ${bodyColor};
    border: none;
    height: 1px;
  }

  b {
    font-weight: ${boldWeight};
  }

  small {
    font-size: .7em;
  }

  strong {
    font-weight: ${boldWeight};
  }

  footer p {
    color: rgba(255, 255, 255, 0.75);
    font-size: 11px;
    text-align: center;
    margin-bottom: 30px;
    font-style: italic;
  }

  a {
    color: ${linkColor.link};
    text-decoration: none;

    &:visited {
      color: ${linkColor.visited};
    }

    &:active {
      color: ${linkColor.active};
    }

    &:hover {
      color: ${linkColor.hover};
    } 
  }

  
`