import React from 'react'
import styled from 'styled-components'
import HramSvgPath from '~/assets/hram.svg'
import { animateScroll as scroll } from 'react-scroll'
import { secondaryColor, linesEm } from '~/styling'

const Hgroup = styled.hgroup`
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;

  img {
    margin: ${linesEm(1)} auto;
    width: 100%;
    object-fit: contain;
    width: 20em;
    max-height: 30vh;
    cursor: pointer;
  }
  hr {
    background: ${secondaryColor};
    height: 2px;
    width: 100%;
    margin: 1px auto 0px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  }
  h1 {
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 3.3em;
    line-height: 1.3;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    color: ${secondaryColor};
  }
  h4 {
    font-weight: 500;
    font-size: 2em;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
    color: ${secondaryColor};
  }
`

const Logo: React.FC = () => {
  return (
    <>
      <Hgroup onClick={() => scroll.scrollTo(0)}>
        <img src={HramSvgPath} alt="NikolaHramVlr.by" onClick={() => scroll.scrollTo(0)} />
        <h1>Храм святителя Николая</h1>
        <hr />
        <h4>в деревне Валерьяново</h4>
      </Hgroup>
    </>
  )
}

export default Logo