import React from 'react'
import styled from 'styled-components'
import { useAppContext, App } from '~/providers'

import { primaryColor, bp, contentBackground1, calcBaseFontSize } from '~/styling'
import { useObservable } from '~/utils'

const Base = styled.div`
  position: relative;
  width: ${bp[0].contentWidth};
  font-size: ${calcBaseFontSize(1.5, bp[0].contentWidth)};
  background: ${contentBackground1};
  @media (min-width: ${bp[1].minWidth}) {
    padding-left: ${bp[1].sidebarWidth};
    font-size: ${calcBaseFontSize(1.7, bp[1].contentWidth)};
  }
  @media (min-width: ${bp[2].minWidth}) {
    font-size: ${calcBaseFontSize(1.4, bp[2].contentWidth)};
    padding-left: ${bp[2].sidebarWidth};
  }
  @media (min-width: ${bp[3].minWidth}) {
    font-size: ${calcBaseFontSize(1.25, bp[3].contentWidth)};
    padding-left: ${bp[2].sidebarWidth};
  }
`

const MenuBtn = styled.div`
  position: fixed;
  top: 12px;
  left: 12px;
  padding: 7px;
  box-sizing: content-box;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  z-index: 10;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${primaryColor};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.6);
  opacity: 0.6;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='M.929 3H25.07M.929 10H25.07M.929 17H25.07'/%3E%3C/g%3E%3C/svg%3E");
  @media (min-width: ${bp[1].minWidth}) {
    display: none;
  }
  transition: left 0.4s ease-out;
  &.drawer {
    left: calc(${bp[0].sidebarWidth} + 12px);
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='square' stroke='%23000' stroke-width='2'%3E%3Cpath d='M4.474 18.526L21.526 1.474M4.474 1.474l17.052 17.052'/%3E%3C/g%3E%3C/svg%3E");
  }
`

const ContentView: React.FC = ({ children }) => {
  const appCtx = useAppContext()
  const [drawer] = useObservable(appCtx.drawer$, appCtx.drawer)

  return (
    <Base>
      {children}
      <MenuBtn
        onClick={() => appCtx.dispatch(App.Actions.TOGGLE_DRAWER)}
        className={drawer ? 'drawer' : ''}
      ></MenuBtn>
    </Base>
  )
}

export default ContentView